import React, { useEffect } from 'react'
import { Typography, Form, Input, Button, message } from 'antd'
import api from '../../api'

export default () => {
  const [form] = Form.useForm()

  const getData = async () => {
    try {
      const { data } = await api.get('/content')
      const content_cskh = JSON.parse(data.data);
      form.setFieldValue('cskh1', content_cskh[0])
      form.setFieldValue('cskh2', content_cskh[1])
      form.setFieldValue('cskh3', content_cskh[2])
      form.setFieldValue('cskh4', content_cskh[3])
      form.setFieldValue('cskh5', content_cskh[4])
      form.setFieldValue('cskh6', content_cskh[5])
      form.setFieldValue('cskh7', content_cskh[6])
      form.setFieldValue('cskh8', content_cskh[7])
      form.setFieldValue('cskh9', content_cskh[8])
      form.setFieldValue('cskh10', content_cskh[9])
      form.setFieldValue('cskh11', content_cskh[10])
      form.setFieldValue('cskh12', content_cskh[11])
      form.setFieldValue('cskh13', content_cskh[12])
      form.setFieldValue('cskh14', content_cskh[13])
      form.setFieldValue('cskh15', content_cskh[14])
      form.setFieldValue('cskh16', content_cskh[15])
      form.setFieldValue('cskh17', content_cskh[16])
      form.setFieldValue('cskh18', content_cskh[17])
      form.setFieldValue('cskh19', content_cskh[18])
      form.setFieldValue('cskh20', content_cskh[19])
      
      const content_num = JSON.parse(data.num);
      form.setFieldValue('num1', content_num[0])
      form.setFieldValue('num2', content_num[1])
      form.setFieldValue('num3', content_num[2])
      form.setFieldValue('num4', content_num[3])
      form.setFieldValue('num5', content_num[4])
      form.setFieldValue('num6', content_num[5])
      form.setFieldValue('num7', content_num[6])
      form.setFieldValue('num8', content_num[7])
      form.setFieldValue('num9', content_num[8])
      form.setFieldValue('num10', content_num[9])
      form.setFieldValue('num11', content_num[10])
      form.setFieldValue('num12', content_num[11])
      form.setFieldValue('num13', content_num[12])
      form.setFieldValue('num14', content_num[13])
      form.setFieldValue('num15', content_num[14])
      form.setFieldValue('num16', content_num[15])
      form.setFieldValue('num17', content_num[16])
      form.setFieldValue('num18', content_num[17])
      form.setFieldValue('num19', content_num[18])
      form.setFieldValue('num20', content_num[19])
    } catch (err) { }
  }

  const onFinish = async values => {
    try {
      await api.put('/content', { 
          link: [values.cskh1 || "", values.cskh2 || "", values.cskh3 || "", values.cskh4 || "", values.cskh5 || "", values.cskh6 || "", values.cskh7 || "", values.cskh8 || "", values.cskh9 || "", values.cskh10 || "", values.cskh11 || "", values.cskh12 || "", values.cskh13 || "", values.cskh14 || "", values.cskh15 || "", values.cskh16 || "", values.cskh17 || "", values.cskh18 || "", values.cskh19 || "", values.cskh20 || ""], 
          num: [values.num1 || "", values.num2 || "", values.num3 || "", values.num4 || "", values.num5 || "", values.num6 || "", values.num7 || "", values.num8 || "", values.num9 || "", values.num10 || "", values.num11 || "", values.num12 || "", values.num13 || "", values.num14 || "", values.num15 || "", values.num16 || "", values.num17 || "", values.num18 || "", values.num19 || "", values.num20 || ""], 
      })
      message.success('Thay đổi thành công')
    } catch (err) { }
  }

  useEffect(() => {
    getData()
  })

  return (
    <div>
      <Typography.Title level={5}> Chỉnh sửa đường dẫn CSKH </Typography.Title>
      <br />
      <Form
        form={form}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 32 }}
        initialValues={{ cskh1: '', cskh2: '', cskh3: '', cskh4: '', cskh5: '', cskh6: '', cskh7: '', cskh8: '', cskh9: '', cskh10: '', cskh11: '', cskh12: '', cskh13: '', cskh14: '', cskh15: '', cskh16: '', cskh17: '', cskh18: '', cskh19: '', cskh20: '', num1: '', num2: '', num3: '', num4: '', num5: '', num6: '', num7: '', num8: '', num9: '', num10: '', num11: '', num12: '', num13: '', num14: '', num15: '', num16: '', num17: '', num18: '', num19: '', num20: '' }}
        onFinish={onFinish}
        autoComplete="off"
        layout="vertical"
      >
        <div>
          <div style={{display:"flex"}}>
          <div>
          <Form.Item
            style={{ width: 350 }}
            label="CSKH1"
            name="cskh1"
          // rules={[{ required: true, message: 'Không thể trống!' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            style={{ width: 350 }}
            label="CSKH2"
            name="cskh2"
          // rules={[{ required: true, message: 'Không thể trống!' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            style={{ width: 350 }}
            label="CSKH3"
            name="cskh3"
          // rules={[{ required: true, message: 'Không thể trống!' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            style={{ width: 350 }}
            label="CSKH4"
            name="cskh4"
          // rules={[{ required: true, message: 'Không thể trống!' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            style={{ width: 350 }}
            label="CSKH5"
            name="cskh5"
          // rules={[{ required: true, message: 'Không thể trống!' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            style={{ width: 350 }}
            label="CSKH6"
            name="cskh6"
          // rules={[{ required: true, message: 'Không thể trống!' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            style={{ width: 350 }}
            label="CSKH7"
            name="cskh7"
          // rules={[{ required: true, message: 'Không thể trống!' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            style={{ width: 350 }}
            label="CSKH8"
            name="cskh8"
          // rules={[{ required: true, message: 'Không thể trống!' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            style={{ width: 350 }}
            label="CSKH9"
            name="cskh9"
          // rules={[{ required: true, message: 'Không thể trống!' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            style={{ width: 350 }}
            label="CSKH10"
            name="cskh10"
          // rules={[{ required: true, message: 'Không thể trống!' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            style={{ width: 350 }}
            label="CSKH11"
            name="cskh11"
          // rules={[{ required: true, message: 'Không thể trống!' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            style={{ width: 350 }}
            label="CSKH12"
            name="cskh12"
          // rules={[{ required: true, message: 'Không thể trống!' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            style={{ width: 350 }}
            label="CSKH13"
            name="cskh13"
          // rules={[{ required: true, message: 'Không thể trống!' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            style={{ width: 350 }}
            label="CSKH14"
            name="cskh14"
          // rules={[{ required: true, message: 'Không thể trống!' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            style={{ width: 350 }}
            label="CSKH15"
            name="cskh15"
          // rules={[{ required: true, message: 'Không thể trống!' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            style={{ width: 350 }}
            label="CSKH16"
            name="cskh16"
          // rules={[{ required: true, message: 'Không thể trống!' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            style={{ width: 350 }}
            label="CSKH17"
            name="cskh17"
          // rules={[{ required: true, message: 'Không thể trống!' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            style={{ width: 350 }}
            label="CSKH18"
            name="cskh18"
          // rules={[{ required: true, message: 'Không thể trống!' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            style={{ width: 350 }}
            label="CSKH19"
            name="cskh19"
          // rules={[{ required: true, message: 'Không thể trống!' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            style={{ width: 350 }}
            label="CSKH20"
            name="cskh20"
          // rules={[{ required: true, message: 'Không thể trống!' }]}
          >
            <Input />
          </Form.Item>
          </div>
          <div>
          <Form.Item
            style={{ width: 350 }}
            label="SL1"
            readonly="" name="num1"
          
          >
            <Input />
          </Form.Item>
          <Form.Item
            style={{ width: 350 }}
            label="SL2"
            readonly="" name="num2"
          
          >
            <Input />
          </Form.Item>
          <Form.Item
            style={{ width: 350 }}
            label="SL3"
            readonly="" name="num3"
          
          >
            <Input />
          </Form.Item>
          <Form.Item
            style={{ width: 350 }}
            label="SL4"
            readonly="" name="num4"
          
          >
            <Input />
          </Form.Item>
          <Form.Item
            style={{ width: 350 }}
            label="SL5"
            readonly="" name="num5"
          
          >
            <Input />
          </Form.Item>
          <Form.Item
            style={{ width: 350 }}
            label="SL6"
            readonly="" name="num6"
          
          >
            <Input />
          </Form.Item>
          <Form.Item
            style={{ width: 350 }}
            label="SL7"
            readonly="" name="num7"
          
          >
            <Input />
          </Form.Item>
          <Form.Item
            style={{ width: 350 }}
            label="SL8"
            readonly="" name="num8"
          
          >
            <Input />
          </Form.Item>
          <Form.Item
            style={{ width: 350 }}
            label="SL9"
            readonly="" name="num9"
          
          >
            <Input />
          </Form.Item>
          <Form.Item
            style={{ width: 350 }}
            label="SL10"
            readonly="" name="num10"
          
          >
            <Input />
          </Form.Item>
          <Form.Item
            style={{ width: 350 }}
            label="SL11"
            readonly="" name="num11"
          
          >
            <Input />
          </Form.Item>
          <Form.Item
            style={{ width: 350 }}
            label="SL12"
            readonly="" name="num12"
          
          >
            <Input />
          </Form.Item>
          <Form.Item
            style={{ width: 350 }}
            label="SL13"
            readonly="" name="num13"
          
          >
            <Input />
          </Form.Item>
          <Form.Item
            style={{ width: 350 }}
            label="SL14"
            readonly="" name="num14"
          
          >
            <Input />
          </Form.Item>
          <Form.Item
            style={{ width: 350 }}
            label="SL15"
            readonly="" name="num15"
          
          >
            <Input />
          </Form.Item>
          <Form.Item
            style={{ width: 350 }}
            label="SL16"
            readonly="" name="num16"
          
          >
            <Input />
          </Form.Item>
          <Form.Item
            style={{ width: 350 }}
            label="SL17"
            readonly="" name="num17"
          
          >
            <Input />
          </Form.Item>
          <Form.Item
            style={{ width: 350 }}
            label="SL18"
            readonly="" name="num18"
          
          >
            <Input />
          </Form.Item>
          <Form.Item
            style={{ width: 350 }}
            label="SL19"
            readonly="" name="num19"
          
          >
            <Input />
          </Form.Item>
          <Form.Item
            style={{ width: 350 }}
            label="SL20"
            readonly="" name="num20"
          
          >
            <Input />
          </Form.Item>
          </div>
          </div>
          <Form.Item style={{ marginTop: 30 }}>
            <Button type="primary" htmlType="submit">
              Thay đổi
            </Button>
          </Form.Item>
        </div>
      </Form>
    </div>
  )
}
